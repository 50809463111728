import React from "react";
import { BsChevronCompactDown, BsMouse } from "react-icons/bs";

type BannerProps = {
  children?: React.ReactNode;
};

export const BannerWrapper = ({ children }: BannerProps) => (
  <div className="h-[calc(30vh_-_4rem)] md:h-[calc(100vh_-_4rem)] 2xl:h-[calc(90vh_-_4rem)] relative">
    <>
      {children}
      <div className="hidden md:flex justify-center items-center flex-col absolute bottom-5 left-0 right-0 text-[#555555]">
        <BsMouse className="w-7 h-7" />
        <BsChevronCompactDown className="mt-1 w-5 h-5 animate-bounce" />
      </div>
    </>
  </div>
);
