import React from "react";

import { PageProps } from "gatsby";

import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BannerWrapper } from "../components/BannerWrapper";
import { Layout } from "../components/themed";
import { graphql } from "gatsby";
import { ContactForm } from "../components/themed/ContactForm";
import { SemiFooter } from "../components";

const Promociones = ({
  data: { images },
}: PageProps<{ images: Queries.FileConnection }>) => {
  const promocionesImg = images.edges.map((edge) => ({
    ...edge.node.childImageSharp,
    name: edge.node.name,
  }));

  return (
    <Layout>
      <BannerWrapper>
        <StaticImage
          src="../images/banners/banner_promociones.jpg"
          alt="Publicidad Aerea - Promociones Vigentes"
        />
      </BannerWrapper>
      <div className="w-[95%] md:w-[80%] max-w-[1200px] mx-auto">
        <div className="text-center font-poppins mb-4">
          <p className="font-semibold text-xl md:text-3xl mb-2">
            Conoce nuestras
          </p>
          <h1 className="text-3xl font-extrabold md:text-5xl">
            Promociones Vigentes
          </h1>
        </div>
        <p className="text-semi-black text-center font-poppins">
          Aerotec Publicidad te ofrece promociones y cupones. Somos una empresa
          dedicada al diseño y fabricación de material publicitario dirigido a
          grandes empresas y pequeños negocios, nuestros productos más populares
          son Sky Dancers, Banderas, Carpas y más. Navega en nuestro sitio web
          para conocer más.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-10">
          {promocionesImg.map((img: any, index: number) => (
            <GatsbyImage
              image={img?.gatsbyImageData!}
              alt={img.name}
              key={index}
              className="rounded-md"
            />
          ))}
        </div>
        <div className="h-14"></div>
        <SemiFooter />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    images: allFile(filter: { relativeDirectory: { eq: "promociones" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
  }
`;

export default Promociones;
